<template>
  <div class="publicize">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide"  :style="{backgroundImage:'url('+homeImge1+')'}" id="publicize1">
          <div class="allneibox allflex publicize1Box">
            <div class="publicize1Box_1" :class="activeIndex==0?'animate__animated animate__fadeInLeftBig':''" :style="{backgroundImage:'url('+homeItem1.typePic+')'}"></div>
            <div class="publicize1Box_2" :class="activeIndex==0?'animate__animated animate__fadeInRightBig':''">
              加入我们
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="publicize2" v-if="homeItem2.remark">
          <div class="publicize2back2"></div>
          <div class="publicize2boxtitle1" :class="activeIndex==1?'animate__animated animate__bounceInDown':''">
            我们是谁？
          </div>
          <div class="publicize2boxtitle2" :class="activeIndex==1?'animate__animated animate__bounceInDown':''">
            {{homeItem2.typeInfo}}
          </div>
          <div class="publicize2box allneibox" :class="activeIndex==1?'animate__animated animate__bounceInUp':''" >
            <div class="publicize2box1">
              <div class="publicize2box1_1"  :style="{backgroundImage:'url('+homeItem2.typePic[0]+')'}"></div>
              <div class="publicize2box1_2">{{ homeItem2.remark[0].name }}</div>
              <div class="publicize2box1_3">{{ homeItem2.remark[0].content }}</div>
            </div>
            <div class="publicize2box1">
              <div class="publicize2box1_12"  :style="{backgroundImage:'url('+homeItem2.typePic[1]+')'}"></div>
              <div class="publicize2box1_2">{{ homeItem2.remark[0].name }}</div>
              <div class="publicize2box1_3">{{ homeItem2.remark[0].content }}</div>
            </div>
            <div class="publicize2box1">
              <div class="publicize2box1_13"  :style="{backgroundImage:'url('+homeItem2.typePic[2]+')'}"></div>
              <div class="publicize2box1_2">{{ homeItem2.remark[0].name }}</div>
              <div class="publicize2box1_3">{{ homeItem2.remark[0].content }}</div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="publicize3">
          <div class="allneibox allflex publicize3box" v-if="homeItem3.remark">
            <div class="publicize3boxleft" :class="activeIndex==2?'animate__animated animate__fadeInLeftBig':''"></div>
            <div class="publicize3boxright" :class="activeIndex==2?'animate__animated animate__fadeInRightBig':''">
              <div class="publicize3boxright1">ADHD行业推动者，构建国际化的数字医疗体系</div>
              <div class="publicize3boxright2" v-html="homeItem3.typeInfo">
              </div>
              <div class="allflex publicize3boxright3" >
                <div class="publicize3boxright3_1">
                  <div class="publicize3boxright3_11"></div>
                  <div class="publicize3boxright3_12">{{ homeItem3.remark[0].name }}</div>
                </div>
                <div class="publicize3boxright3_1">
                  <div class="publicize3boxright3_11"></div>
                  <div class="publicize3boxright3_12">{{ homeItem3.remark[1].name }}</div>
                </div>
                <div class="publicize3boxright3_1">
                  <div class="publicize3boxright3_11"></div>
                  <div class="publicize3boxright3_12">{{ homeItem3.remark[2].name }}</div>
                </div>
              </div>
              <div class="publicize3boxright3_1" style="justify-content: left; margin-top: 5vh;">
                <div class="publicize3boxright3_11"></div>
                <div class="publicize3boxright3_12">{{ homeItem3.remark[3].name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" id="publicize4">
          <div class="publicize3back2"></div>
          <div class="allneibox  publicize4box">
            <div class="publicize4boxTitle">来寻找你的适合岗位，加入BQBrain大家庭</div>
            <div class="publicize4boxitem">
              <div class="publicize4boxitembox" v-for="(item,index) in homeItem4.remark" :key="index" v-if="homeItem4.remark"
                   :style="{'marginTop':index>0?'10px':''}" @click="changejionData(index)">
                <div class="publicize4boxitembox1">
                  <div>{{ item.name1 }}</div>
                  <img src="@/static/studyAdhd/zhankai.png" style="width: 16px;height: 9px" v-if="!item.isshow">
                  <img src="@/static/studyAdhd/shouqi.png" style="width: 16px;height: 9px" v-if="item.isshow">
                </div>
                <div class="publicize4boxitembox2">
                  {{ item.name2 }}
                </div>
                <div class="publicize4boxitembox3" v-if="item.isshow">
                  <div v-html="item.content"></div>
                </div>
                <div class="publicize4boxitembox4" v-if="item.isshow">
                  投递简历
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="swiper-slide" id="publicize5">
          <div class="publicize7">
            <div class="publicize7box">
              <div class="studyAdhdbox" style="display: flex;justify-content: space-between">
                <div class="publicize7boxleft" :class="activeIndex==4?'animate__animated animate__fadeInLeftBig':''">
                  <div class="publicize7boxleft1"></div>
                  <div class="publicize7boxleft2">
                    <div>江苏省南京市雨花台区大数据基地5栋203</div>
                    <div>8602788915</div>
                    <div>XXXX@bqbrain.com</div>
                  </div>
                </div>
                <div class="publicize7boxright" :class="activeIndex==4?'animate__animated animate__fadeInRightBig':''">
                  <div class="publicize7_boxbox">
                    <div style="font-size: 36px;font-weight: bold;">了解ADHD</div>
                    <div>什么是注意力缺陷多动障碍</div>
                    <div>ADHD的数据和统计</div>
                    <div>诊断注意力缺陷多动障碍</div>
                    <div>注意力缺陷多动障碍的治疗</div>
                    <div>补充治疗</div>
                    <div>注意力缺陷多动障碍的共患病</div>
                    <div>常见问题</div>
                    <div>ADHD的误解</div>
                    <div>前沿</div>
                  </div>
                  <div class="publicize7_boxbox">
                    <div style="font-size: 36px;font-weight: bold;">产品&解决方案</div>
                    <div>一键式海筛工具</div>
                    <div>医教家全面测评量表</div>
                    <div>精准用药</div>
                    <div>多维度神经心理测验</div>
                    <div>注意力测评及训练</div>
                    <div>家/校目标行为干预数字化工具</div>
                    <div>儿童专属正念</div>
                    <div>真实世界研究工具</div>
                    <div>家长培训课程</div>
                    <div>居家感与训练</div>
                    <div>IEP项目</div>
                  </div>
                  <div class="publicize7_boxbox" style="width: 230px;">
                    <div style="font-size: 36px;font-weight: bold;">宣传&教育</div>
                    <div>ADHDNews</div>
                    <div>ADHD月刊</div>
                    <div>培训与活动</div>
                    <div>客户故事</div>
                    <div>播客</div>
                    <div style="font-size: 36px;margin-top: 50px;font-weight: bold;">加入皕启</div>
                    <div>皕启脑科学</div>
                    <div>招聘岗位</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="studyAdhdfoother" :class="activeIndex==4?'animate__animated animate__backInUp':''">
              <div class="studyAdhdbox"
                   style="display: flex;justify-content: center;flex-wrap: wrap;color: #FFFFFF;align-items: center;height: 100%;">
                <div >
                  备案号:南京皕启科技有限公司 © 2023
                  <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #FFFFFF;text-decoration:none">苏ICP备2023005663号-1 | 苏ICP备2023005663号</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import {websiteApiType} from "@/api/api";

export default {
  data() {
    return {
      jionData: [
        {
          name: "产品经理",
          address: "江苏,南京",
          isshow: true,
          contentHtml: "1.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  2.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  3.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  4.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  5.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情",
        }, {
          name: "测试总监",
          address: "江苏,南京",
          isshow: false,
          contentHtml: "1.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  2.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  3.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  4.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  5.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情",
        }, {
          name: "心理师",
          address: "江苏,南京",
          isshow: false,
          contentHtml: "1.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  2.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  3.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  4.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  5.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情",
        }, {
          name: "CTO",
          address: "江苏,南京",
          isshow: false,
          contentHtml: "1.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  2.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  3.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  4.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情<br/>\n" +
              "                  5.职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情职位详情",
        },
      ],
      swiperOption: {},
      activeIndex: 0,
      homeImge1:"",
      contentData:"",
      homeItem1:"",
      homeItem2:"",
      homeItem3:"",
      homeItem4:"",
    };
  },
  created() {
    this.$parent.changeTitleMenu(0)
  },
  computed: {},
  mounted() {
    this.getSwiper()
    this.getAllData()
    this.getContData()
  },
  methods: {
    getContData(){
      let that = this
      let data = {
        pageNum: 1,
        pageSize: 100,
        parentId: 10,
      }
      websiteApiType(data).then(res => {
        that.contentData = res.data.data.records
        for (let i = 0; i < this.contentData.length; i++) {
          if (this.contentData[i].typeName == '加入我们') {
            this.homeItem1 =this.contentData[i]
          }
          if (this.contentData[i].typeName == '我们是谁?') {
            this.homeItem2 =this.contentData[i]
            this.homeItem2.remark = JSON.parse(this.contentData[i].remark)
            this.homeItem2.typePic = this.contentData[i].typePic.split(',')
          }
          if (this.contentData[i].typeName == 'ADHD行业推动者，构建国际化的数字医疗体系') {
            this.homeItem3 =this.contentData[i]
            this.homeItem3.remark = JSON.parse(this.contentData[i].remark)
          }
          if (this.contentData[i].typeName == '来寻找你的适合岗位，加入BQBrain大家庭') {
            this.homeItem4 =this.contentData[i]
            let remarkData = JSON.parse(this.contentData[i].remark)
            this.homeItem4.remark = remarkData.map(item=>{
              return{
                ...item,
                isshow:false,
                name1:item.name.split('-')[0],
                name2:item.name.split('-')[1],
              }
            })
          }
        }
      })
    },
    getAllData() {
      let that =this
      let data = {
        pageNum: 1,
        pageSize: 100,
        parentId: 5,
      }
      websiteApiType(data).then(res => {
        let imgData = res.data.data.records
        for (let i = 0; i < imgData.length; i++) {
          let nameType = imgData[i].typeName
          if (nameType == "模块1") {
            that.homeImge1 = imgData[i].typePic;
          }
        }
      }).catch(err => console.log(err))
    },
    changejionData(index) {
      this.homeItem4.remark[index].isshow = !this.homeItem4.remark[index].isshow
    },
    changewi() {
      this.swiperOption.slideTo(0);
    },
    getSwiper() {
      let that = this
      this.swiperOption = new Swiper(".swiper-container", {
        direction: 'vertical',
        mousewheel: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          touchStart: function (e) {
            // 打印当前索引
            that.activeIndex = that.swiperOption.activeIndex
            that.$parent.changeTitleMenu(that.activeIndex)
          },
          slideChange: function (e) {
            that.activeIndex = that.swiperOption.activeIndex
            that.$parent.changeTitleMenu(that.activeIndex)
          },
        },
      })
    }
  }
};
</script>

<style scoped>
.publicize4boxitembox4 {
  width: 181px;
  height: 48px;
  background: linear-gradient(135deg, #005DFF 0%, #55FDF4 100%);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 2.4vw;
}

.publicize4box {
  margin-top: 20vh;
}

.publicize4boxTitle {
  font-size: 1.8vw;
  font-weight: 500;
  color: #005DFF;
}

.publicize4boxitem {
  margin-top: 4.3vh;
  overflow-x: auto;
  height: 65vh;
}

.publicize4boxitembox {
  background: #FFFFFF;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  border: 1px solid #E5EAF4;
  box-sizing: border-box;
  padding: 3vw 2.5vw 3vw;
}

.publicize4boxitembox1 {
  display: flex;
  justify-content: space-between;
  font-size: 1vw;
  font-weight: 500;
  color: #001C4B;
}

.publicize4boxitembox2 {
  font-size: 0.72vw;
  font-weight: 400;
  color: #5A7184;
  margin-top: 14px;
}

.publicize4boxitembox3 {
  margin-top: 14px;
  font-size: 1vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #5A7184;
  line-height: 2;
}

.publicize3box {
  margin-top: 20vh;
}

.publicize3boxleft {
  width: 25.83vw;
  height: 62.96vh;
  background-image: url("@/static/joinUs/publicize3boxleft.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.publicize3boxright3 {
  width: 90%;
  margin-top: 5vh;
}

.publicize3boxright3_1 {
  display: flex;
  justify-content: space-between;
}

.publicize3boxright3_12 {
  font-size: 1vw;
  font-weight: bold;
  color: #0E1828;
}

.publicize3boxright3_11 {
  width: 1.7vw;
  height: 1.7vw;
  background-image: url("@/static/joinUs/publicize3boxright3_12.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.publicize3boxright {
  width: 44.9vw;
  height: 51vh;
  box-sizing: border-box;
  padding: 5.2vw 2vw 5.2vw;
  background: #FFFFFF;
  box-shadow: -50px 50px 50px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}

.publicize3boxright1 {
  font-size: 1.8vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
}

.publicize3boxright2 {
  margin-top: 2vh;
  font-size: 0.8vw;
  line-height: 2;
  width: 90%;
  font-weight: 400;
  color: #0E1828;
}

.publicize2boxtitle1 {
  font-size: 1.8vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #005DFF;
  margin-top: 12vh;
  width: 100%;
  text-align: center;
}

.publicize2boxtitle2 {
  margin: auto;
  margin-top: 6vh;
  line-height: 2;
  width: 75vw;
  font-size: 1vw;
  font-weight: 400;
  color: #0E1828;
  text-align: center;
}

.publicize2box {
  display: flex;
  margin-top: 12vh;
  justify-content: space-between;
}

.publicize2box1 {
  width: 21.45vw;
  height: 24vh;
}

.publicize2box1_1 {
  width: 4.37vw;
  height: 4.37vw;
  background-image: url("@/static/joinUs/publicize2box1_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
}

.publicize2box1_12 {
  width: 4.37vw;
  height: 4.37vw;
  background-image: url("@/static/joinUs/publicize2box1_2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
}

.publicize2box1_13 {
  width: 4.37vw;
  height: 4.37vw;
  background-image: url("@/static/joinUs/publicize2box1_3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
}

.publicize2box1_2 {
  margin-top: 4.3vh;
  font-size: 0.8vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #0E1828;
  text-align: center;
  width: 100%;
}

.publicize2box1_3 {
  margin-top: 4.3vh;
  font-size: 1.2vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #005DFF;
  text-align: center;
  width: 100%;
}

.publicize1Box {
  margin-top: 27.22vh;
}

#publicize1 {
  background-image: url("@/static/joinUs/publicize1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.publicize1Box_1 {
  background-image: url("@/static/joinUs/publicize1_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 41.35vw;
  height: 51.57vh;
}

.publicize1Box_2 {
  font-size: 5.7vw;
  font-family: SourceKeynoteartHans;
  font-weight: 400;
  color: #15233B;
}

#publicize1, #publicize2, #publicize3, #publicize4, #publicize5, #publicize6, #publicize7 {
  position: relative;
  width: 100%;
  height: 100%;
}

.publicize {
  width: 100vw;
  height: 100vh;
  min-width: 1200px;
  overflow: hidden;
}

.publicize7box {
  background-image: url("@/static/studyAdhd/studyAdhd11.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 70vh;
}

#publicize3 {
  background: linear-gradient(180deg, #E2EEFF 0%, #E2EEFF 100%);
}

#publicize4 {
  background: linear-gradient(180deg, #E2EEFF 0%, rgba(250, 251, 251, 0) 100%);
}

.studyAdhdfoother {
  width: 100%;
  height: 10vh;
  background: #363636;
}

.publicize7boxleft1 {
  background-image: url("@/static/studyAdhd/studyAdhd11boxleft1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 13.69vw;
  height: 9.7vh;
}

.studyAdhdbox {
  width: 75vw;
  margin: auto;
  position: relative;
}

.publicize7boxleft2 {
  font-size: 0.6vw;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #8D8D8D;
  margin-top: 3vh;
}

.publicize7 {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.publicize7boxright {
  display: flex;
  justify-content: space-between;
  width: 44vw;
  line-height: 3;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  right: 0 !important;
  left: 3% !important;
  width: 50px !important;
}

.swiper-pagination >>> .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  border: 0;
  margin: 10px 0 0 10px;
  border-radius: 50%;
  background: #005dff;
}

.swiper-pagination >>> .swiper-pagination-bullet-active {
  margin: 10px 0 0 7px;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: linear-gradient(180deg, #55FDF4 0%, #005DFF 100%);
}

.publicize2back2 {
  background-image: url("@/static/joinUs/publicize2back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35vw;
  height: 35vw;
  position: absolute;
  left: -28vw;
  top: 15vh;
  animation: rotate 15s linear infinite;
}

.publicize3back2 {
  background-image: url("@/static/joinUs/publicize3back2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35vw;
  height: 35vw;
  position: absolute;
  left: -28vw;
  top: 15vh;
  animation: rotate 15s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(359deg)
  }
}

</style>
